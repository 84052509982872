body {
  background: linear-gradient(#12134e, #090923, #12134e);
  height: 100vh;

  color: white;
}

.App {
  text-align: center;
}

.Introduction {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 700px) {
  .Introduction {
    flex-direction: column;
  }
}

.Introduction img {
  width: 20vw;
  height: 20vw;
  border-radius: 100%;
}

@media (max-width: 700px) {
  .Introduction img{
    width: 25vh;
    height: 25vh;
  }
}

.Introduction-text {
  background-color: #282863;
  border-radius: 10px;
  border: 20px solid transparent;
  text-align: start;
  color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 20px;
}

.Introduction-text:hover {
  background-color: #323297;
  color: #000000;
}

.Skills {
    display: flex;
    flex-direction: column;
    align-items: center;
  justify-content: center;
}

.Skills h1 {
transform: translateY(30px);
}

.SkillButton {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  //height: 10vw;
  //width: 10vw;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.SkillButton:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

.SkillText {
  display: flex;
    flex-direction: column;
    align-items: flex-start;

  text-align: start;

  border-radius: 15px;
  width: 45vw;

  padding-left: 10px;
  padding-right: 10px;

  margin-top: 1vw;
  background-color: #282863;
  transition: background-color 0.3s ease;
}

@media (max-width: 700px) {
    .SkillText {
      width: 80vw;
      align-items: center;
    }
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate360 1s linear;
}