.title-bar {
    background-color: #101142;
    height: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ryannn-icon img {
    width: 4vh;
    height: 4vh;
    padding: 1vh;
    cursor: pointer;
}

#menu {
    padding-right: 1vh;
    padding-left: 1vh;
    width: 4vh;
    height: 4vh;
    text-justify: initial;
}